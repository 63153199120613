<template>
  <div>
    <v-card id="request-reject-list">
      <v-card-text class="d-flex align-center request-appbar-color flex-wrap pb-0">
        <h2 style="color: white !important;" class="mb-4">{{ t('Rejected requests') }}</h2>
        <v-spacer></v-spacer>
        <div class="d-flex align-center pb-5">
          <!-- search request -->
          <v-tooltip
            :left="$vuetify.breakpoint.smAndUp"
            :top="$vuetify.breakpoint.xsOnly"
            :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
            :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
            :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
            max-width="250"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                size="30"
                class="mr-3 hover-pointer"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  color="white"
                  size="25"
                >
                  {{ icons.mdiAlertCircleOutline }}
                </v-icon>
              </v-avatar>
            </template>
            <h3 class="my-2">
              {{ t('How to use the search bar') }}
            </h3>
            <p class="mb-1">
              {{ t('You can search using') }}:
            </p>
            <ul class="mb-2">
              <li>
                {{ t('Record Number') }}
              </li>
            </ul>
          </v-tooltip>
          <v-text-field
            v-model="searchText"
            :append-icon="icons.mdiMagnify"
            single-line
            dense
            outlined
            hide-details
            :placeholder="t('Search Record')"
            class="request-appbar-item"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="request-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="rejectedTableColumns"
        :items="requests"
        :items-per-page="100"
        :loading="loading"
        hide-default-footer
        :disable-sort="true"
        mobile-breakpoint="0"
        class="text-no-wrap"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- Auditor actions -->
            <template v-if="can('write', 'ReportActions')">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :to="item.action === 'Edit' || 'Create' ? requestView(item) : requestVoid(item)"
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ icons.mdiEyeOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
            </template>
            <!-- Clerk actions -->
            <template v-else-if="userData.id === item.requested_by_id">
              <!-- Edit -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :to="requestEdit(item)"
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ item.action === 'Edit' ? icons.mdiPencilOutline : icons.mdiEyeOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ item.action === 'Edit' ? 'Edit' : 'View' }}</span>
              </v-tooltip>

              <!-- Void -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="voidableRequest = item; isVoidDialogVisible = true"
                  >
                    <v-icon size="18">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Void</span>
              </v-tooltip>
            </template>
          </div>
        </template>

        <!-- Requested action -->
        <template #[`item.action`]="{item}">
          <v-chip
            label
            color="primary"
            :class="`v-chip-light-bg my-auto ${requestActionColor(item.action)}--text`"
          >
            {{ item.action }}
          </v-chip>
        </template>

        <!-- Record Number -->
        <template #[`item.record_number`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="recordPreview(item)"
          >
            {{ item.record_number }}
          </router-link>
        </template>
      </v-data-table>

      <!-- Void dialog -->
      <v-dialog
        v-model="isVoidDialogVisible"
        width="500"
      >
        <v-card>
          <v-card-title
            v-if="voidableRequest"
            class="d-flex align-center mv-4"
          >
            Void request on {{ voidableRequest.record_number }}
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="isVoidDialogVisible = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            This request will be voided. It won't be taken into any calculations.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="error"
              block
              :loading="voidLoading"
              :disabled="voidLoading"
              class="mt-3"
              @click="voidRequest()"
            >
              Yes, remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="request-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiAlertCircleOutline,
  mdiMagnify,
  mdiEyeOutline,
  mdiClose,
} from '@mdi/js'
import {
  onMounted,
  ref,
  inject,
  watch,
  computed,
} from '@vue/composition-api'

export default {
  props: {
    record: {
      type: String,
      default: () => null,
    },
  },
  setup(props) {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')

    // Table Handlers
    const rejectedTableColumns = computed(() => (
      [
        { text: t('Actions'), width: '90', value: 'actions' },
        {
          text: t('Requested Action'),
          align: 'center',
          width: '190',
          value: 'action',
        },
        { text: t('Record No.'), value: 'record_number' },
        { text: t('Record Type'), value: 'record_type' },
        { text: t('Rejection Date'), value: 'rejected_at' },
        { text: t('Rejected by'), value: 'responded_by' },
      ]
    ))

    // Properties
    const requests = ref([])
    const tablePagination = ref({
      current_page: 1,
      per: 5,
      total_count: 0,
      total_page: 0,
    })
    const listLengthOptions = ref([5, 10, 20])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const loading = ref(false)
    const searchText = ref('')
    const isVoidDialogVisible = ref(false)
    const voidableRequest = ref({})
    const voidLoading = ref(false)

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchRequests = () => {
      store
        .dispatch('requestStore/fetchRequests', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          search_text: searchText.value,
          status: 'Rejected',
          record_type: props.record,
        })
        .then(response => {
          const { data, pagination } = response.data
          requests.value = data
          requests.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          tablePagination.value = pagination
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching requests. Please refresh!')
        })
    }
    const voidRequest = () => {
      voidLoading.value = true
      store
        .dispatch('requestStore/voidRequest', { id: voidableRequest.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchRequests()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding request. Please refresh!')
        })
    }
    const pageChange = () => {
      isLoading()
      fetchRequests()
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      isLoading()
      fetchRequests()
    }
    const requestEdit = request => {
      const routerTo = {
        params: { id: request.record_id, request_id: request.id, action: request.action },
      }
      switch (request.record_type) {
        case 'Invoice':
          routerTo.name = 'apps-request-edit-i'
          break
        case 'Quotation':
          routerTo.name = 'apps-request-edit-q'
          break
        case 'Receipt':
          routerTo.name = 'apps-request-edit-r'
          break
        case 'Broker':
          routerTo.name = 'apps-request-edit-b'
          break
        case 'Corporate':
          routerTo.name = 'apps-request-edit-c'
          break
        case 'NiagamasLoyalty':
          routerTo.name = 'apps-request-edit-l'
          break
        default:
          routerTo.name = 'apps-request-list'
      }

      return routerTo
    }
    const requestView = request => {
      const routerTo = {
        params: { id: request.record_id, request_id: request.id },
      }
      switch (request.record_type) {
        case 'Invoice':
          routerTo.name = 'apps-request-view-i'
          break
        case 'Quotation':
          routerTo.name = 'apps-request-view-q'
          break
        case 'Receipt':
          routerTo.name = 'apps-request-view-r'
          break
        case 'Broker':
          routerTo.name = 'apps-request-view-b'
          break
        case 'Corporate':
          routerTo.name = 'apps-request-view-c'
          break
        case 'NiagamasLoyalty':
          routerTo.name = 'apps-request-view-l'
          break
        default:
          routerTo.name = 'apps-request-list'
      }

      return routerTo
    }
    const requestVoid = request => {
      const routerTo = {
        params: { id: request.record_id, request_id: request.id },
      }
      switch (request.record_type) {
        case 'Invoice':
          routerTo.name = 'apps-request-void-i'
          break
        case 'Quotation':
          routerTo.name = 'apps-request-void-q'
          break
        case 'Receipt':
          routerTo.name = 'apps-request-void-r'
          break
        case 'Broker':
          routerTo.name = 'apps-request-void-b'
          break
        case 'Corporate':
          routerTo.name = 'apps-request-void-c'
          break
        case 'NiagamasLoyalty':
          routerTo.name = 'apps-request-void-l'
          break
        default:
          routerTo.name = 'apps-request-list'
      }

      return routerTo
    }
    const voidedRecord = request => {
      const routerTo = {
        params: { id: request.record_id },
      }
      switch (request.record_type) {
        case 'Invoice':
          routerTo.name = 'apps-invoice-preview'
          break
        case 'Quotation':
          routerTo.name = 'apps-quotation-preview'
          break
        case 'Receipt':
          routerTo.name = 'apps-receipt-preview'
          break
        default:
          routerTo.name = 'apps-request-list'
      }

      return routerTo
    }
    const recordPreview = record => {
      const routerTo = {
        params: { id: record.record_id },
      }
      switch (record.record_type) {
        case 'Invoice':
          routerTo.name = 'apps-invoice-preview'
          break
        case 'Quotation':
          routerTo.name = 'apps-quotation-preview'
          break
        case 'Receipt':
          routerTo.name = 'apps-receipt-preview'
          break
        default:
          routerTo.name = 'apps-request-list'
      }

      return routerTo
    }
    const requestActionColor = action => {
      switch (action) {
        case 'Edit':
          return 'primary'
        case 'Void':
          return 'error'
        case 'Create':
          return 'success'
        default:
          return ''
      }
    }

    // Watch
    let timer = null
    watch([searchText], () => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        isLoading()
        fetchRequests()
      }, 200)
    })

    // Mounted
    onMounted(() => {
      fetchRequests()
    })

    return {
      t,
      can,
      userData,
      requests,
      loading,
      rejectedTableColumns,
      tablePagination,
      listLengthOptions,
      searchText,
      listLengthChange,
      pageChange,
      requestEdit,
      requestView,
      requestVoid,
      voidedRecord,
      recordPreview,
      voidRequest,
      isVoidDialogVisible,
      voidLoading,
      voidableRequest,
      requestActionColor,

      icons: {
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiAlertCircleOutline,
        mdiMagnify,
        mdiEyeOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
#request-reject-list {
  .request-list-row-selection {
    max-width: 5.3rem;
  }
  .request-appbar-item {
    max-width: 12.2rem;
  }
  .request-appbar-color {
    background-color: #D64942;
  }
  .v-input.request-appbar-item {
    background-color: white !important;
  }
  .theme--dark.request-appbar-item input::placeholder,
  .theme--dark.request-appbar-item .v-icon {
    color: #928C99 !important;
  }
}
</style>

<template>
  <div>
    <v-row class="mb-5">
      <v-col
        cols="12"
        md="11"
      >
        <v-tabs
          id="spd-tab-items"
          v-model="recordTypeFilter"
          grow
        >
          <v-tab
            v-for="record in recordTypeOptions"
            :key="record"
          >
            {{ record === 'NiagamasLoyalty' ? 'Niagamas Loyalty' : record }}
            <v-badge
              v-if="pendingRecords(record) > 0 && userRole === 'Auditor'"
              color="error"
              inline
              :content="pendingRecords(record)"
              :value="pendingRecords(record)"
            >
            </v-badge>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-tabs-items
      v-model="recordTypeFilter"
    >
      <v-tab-item
        v-for="record in recordTypeOptions"
        :key="record"
        :tab-value="record"
      >
        <PendingList
          :record="record"
          class="mb-5"
        />
        <ApproveList
          :record="record"
          class="mb-5"
        />
        <RejectList :record="record" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
} from '@vue/composition-api'
import store from '@/store'
import snackbarService from '@/services/snackbar.service'
import ApproveList from './ApproveList.vue'
import RejectList from './RejectList.vue'
import PendingList from './PendingList.vue'

export default {
  components: {
    ApproveList,
    RejectList,
    PendingList,
  },
  setup() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const userRole = userData && userData.role ? userData.role : null

    const recordTypeFilter = ref(null)
    const recordTypeOptions = ref(['Receipt', 'Invoice', 'Quotation', 'Broker', 'Corporate', 'NiagamasLoyalty'])

    const fetchPendingRequests = () => {
      store
        .dispatch('fetchPendingRequests')
        .then(response => {
          const { data } = response.data
          store.dispatch('setPendingRequests', data)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching pending requests. Please refresh!')
        })
    }

    const pendingRecords = record => {
      switch (record) {
        case 'Receipt':
          return store.state.pendingRequests.pending_receipts
        case 'Invoice':
          return store.state.pendingRequests.pending_invoices
        case 'Quotation':
          return store.state.pendingRequests.pending_quotations
        case 'Broker':
          return store.state.pendingRequests.pending_brokers
        case 'Corporate':
          return store.state.pendingRequests.pending_corporates
        case 'NiagamasLoyalty':
          return store.state.pendingRequests.pending_loyalties
        default:
          return ''
      }
    }

    onMounted(() => {
      fetchPendingRequests()
    })

    return {
      userRole,
      pendingRecords,
      recordTypeFilter,
      recordTypeOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>
